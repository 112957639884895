<template>
  <section class="edit-checklist">
    <row container :gutter="20">
      <column :xs="12" :md="6" :lg="12">
        <form>
          <div class="input-group">
            <label for="name">Navn</label>
            <input type="text" class="input" name="name" v-model="editableChecklist.name" />
          </div>
          <div class="input-group">
            <label for="name">Beskrivelse</label>
            <textarea
              class="input"
              rows="10"
              name="description"
              v-model="description"
            ></textarea>
          </div>
          <EditChecklistItem
            @editChecklistItemEvent="editChecklistItem"
            @imageChanged="imageChanged"
            @removeCheckListItem="removeChecklistItem"
            v-for="item in editableChecklist.checklistItems"
            :key="item.itemNumber"
            :checklistItem="item"
          ></EditChecklistItem>
        </form>
      </column>
    </row>
    <row container :gutter="20">
      <column :xs="6" :md="6" :lg="6">
        <Button
          @click.prevent="addChecklistItem()"
          symbol="plus"
          size="full"
          text="Sjekkpunkt"
          color="lightgreen"
        ></Button>
      </column>
      <column :xs="6" :md="6" :lg="6">
        <Button
          @click.prevent="saveCheckList()"
          size="full"
          text="Lagre"
          color="green"
        ></Button>
      </column>
    </row>
  </section>
</template>
<script>
import ChecklistService from '@/services/ChecklistService';
import EditChecklistItem from '@/components/checklists/EditChecklistItem';
import Button from '@/components/Button';

export default {
  name: 'editChecklist',
  components: {
    EditChecklistItem,
    Button
  },
  props: {
    cabin: Object,
    cabinUserRoleId: Number,
  },
  data() {
    return {
      checklistItemNumber: null,
      checklist: undefined,
      editableChecklist: {},
      description: undefined,
      newImages: []
    };
  },
  methods: {
    isCabinOwner() {
      if (this.cabinUserRoleId === 1) return true;

      return false;
    },
    isCabinAdministrator() {
      if (this.cabinUserRoleId === 2) return true;

      return false;
    },
    isCabinUser() {
      if (this.cabinUserRoleId === 3) return true;

      return false;
    },
    getChecklistDescription() {
      this.description = this.checklist.content[0].text;
    },
    saveCheckList() {
      let content = [
        {
          id: this.editableChecklist.content[0].id,
          contentTypeid: 1,
          text: this.description,
          contentLanguageId: 1
        }
      ];

      this.editableChecklist.checklistItems.map(item => {
        item.content = item.content.filter((filt) => {
          return filt.contentTypeId !== 5
        })
      })

      ChecklistService.updateChecklist(
        this.editableChecklist.id,
        this.editableChecklist.name,
        content,
        this.editableChecklist.checklistItems
      )
      .then(async res => {
        if(this.newImages.length > 0) {
          this.newImages.map((image) => {
            let item = res.data.checklistItems.find((list) => {
              return list.itemNumber === image.checklistItemId;
            });

            let oldImage = item.content.find((old) => {
              return old.contentTypeId === 5;
            });

            if(oldImage) {
              ChecklistService.deleteChecklistItemContent(oldImage.id).then(res => {
                console.log(res);
                ChecklistService.addChecklistItemContent(
                  item.id,
                  image.contentLanguageId,
                  image.contentTypeId,
                  image.text,
                  image.contentAsBase64,
                  image.contentName,
                  image.default,
                  image.sequence
                ).then(res => {
                  console.log(res);
                  console.log('Added Image to Checklist Item')
                })
                .catch(err => {
                  console.log(err)
                });
              })
            } else {
              ChecklistService.addChecklistItemContent(
                item.id,
                image.contentLanguageId,
                image.contentTypeId,
                image.text,
                image.contentAsBase64,
                image.contentName,
                image.default,
                image.sequence
              ).then(res => {
                console.log(res);
                console.log('Added Image to Checklist Item')
              })
              .catch(err => {
                console.log(err)
              });
            }

            
          })
        }
        this.$toast.success(`Redigering av sjekkliste fullført`);
        this.$router.push({ name: 'editChecklists'});
      })
      .catch(err => {
        console.log(err);
        this.$toast.error(`Kunne ikke redigere sjekkliste`);
      });
    },
    removeChecklistItem(val) {
      let itemIndex = this.editableChecklist.checklistItems.findIndex(item => {
        return item.itemNumber === val.itemNumber;
      });

      this.editableChecklist.checklistItems.splice(itemIndex, 1);
    },
    editChecklistItem(val) {
      let itemIndex = this.editableChecklist.checklistItems.findIndex(item => {
        return item.itemNumber === val.itemNumber;
      });
      if (itemIndex == -1) {
        this.editableChecklist.checklistItems.push(val);
      } else {
        this.editableChecklist.checklistItems.splice(itemIndex, 1, val);
      }
    },
    imageChanged(val) {
      let content = {
        checklistItemId: val.id,
        contentLanguageId: 1,
        contentTypeId: 5,
        text: val.name,
        contentAsBase64: val.base64,
        contentName: val.name,
        default: true,
        sequence: 0
      }

      let index = this.newImages.findIndex((item) => {
        return item.checklistItemId === val.id;
      })

      if(index === -1) {
        this.newImages.push(content);
      } else {
        this.newImages.splice(index, 0, content);
      }
    },
    addChecklistItem() {
      this.checklistItemNumber = this.editableChecklist.checklistItems[this.editableChecklist.checklistItems.length - 1].itemNumber + 1;
      this.editableChecklist.checklistItems.push({
        itemNumber: this.checklistItemNumber,
        content: [
          {
            contentTypeid: 2,
            text: '',
            contentLanguageId: 1
          },
          {
            contentTypeid: 1,
            text: '',
            contentLanguageId: 1
          }
        ]
      });
    }
  },
  created() {
    this.$nextTick(() => {
      this.editableChecklist = Object.assign({}, this.checklist);
      this.description = this.checklist.content[0].text;
    })
  },
  async beforeRouteEnter(to, from, next) {
    const { data } = await ChecklistService.getChecklist(to.params.checklistId);
    next((vm) => {
      if((vm.isCabinAdministrator() || vm.isCabinOwner())) {
        if (data) {
          vm.checklist = data;
        }
      } else {
        vm.$router.push(from);
      }
    });
  }
};
</script>
<style lang="scss" scoped>
  .edit-checklist {
    @include gutter-padding;
    @media (max-width: $breakpoint-mobile) {
      margin-bottom: 6rem;
    }
    @media (min-width: $breakpoint-mobile) {
      margin: 4rem 0;
    }
  }
</style>